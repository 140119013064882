import React, { useState } from "react";
import Header from "../components/Header/Header";
import yep from "../assets/5.jpg";
import Footer from "../components/Footer/Footer";
import ApplicationTimeline from "../components/ApplicationTimeline/ApplicationTimeline";
import StepOne from "../components/ApplicationStep/StepOne";
import StepTwo from "../components/ApplicationStep/StepTwo";
import { useNavigate } from "react-router-dom";

const Apply = () => {
  let navigate = useNavigate();
  let [step, setStep] = useState(1);

  const [loanAmount, setLoanAmount] = useState(50);
  const [loanTerm, setLoanTerm] = useState(4);
  const [demonstrableRepayment, setDemonstrableRepayment] = useState("");

  const onLoanTermChange = (e) => {
    setLoanTerm(e.target.value);
  };
  const onDemonstrableRepaymentChange = (str) => {
    setDemonstrableRepayment(str);
  };

  const onHandleLoanAmountChange = (amount) => {
    setLoanAmount(amount);
  };

  const stepOneExitHandler = () => {
    window.analytics.track(`apply.step1.complete`);
    setStep(2);
  };

  const stepTwoExitHandler = (id, hash) => {
    window.analytics.track(`apply.step2.complete`);
    navigate(`/apply/${id}/${hash}`, { replace: false });
  };

  const stepTwoBackHandler = () => {
    setStep(1);
  };

  const stepRenderer = (step) => {
    switch (step) {
      case 1:
        return (
          <StepOne
            loanAmount={loanAmount}
            onHandleLoanAmountChange={onHandleLoanAmountChange}
            onLoanTermChange={onLoanTermChange}
            onDemonstrableRepaymentChange={onDemonstrableRepaymentChange}
            exitHandler={stepOneExitHandler}
          />
        );
      case 2:
        return (
          <StepTwo
            backHandler={stepTwoBackHandler}
            exitHandler={stepTwoExitHandler}
            term={loanTerm}
            demonstrableRepayment={demonstrableRepayment}
            financeAmount={loanAmount}
          />
        );
      default:
        return (
          <StepOne
            loanAmount={loanAmount}
            onHandleLoanAmountChange={onHandleLoanAmountChange}
            exitHandler={stepOneExitHandler}
          />
        );
    }
  };

  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yep} className="min-w-full object-cover" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-2xl font-medium text-gray-900">
            Apply
          </h1>
          <span className="mb-4 leading-tight text-lg drop-shadow block">
            Let's find you the right help
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container pt-16 pb-8 px-8 sm:px-0 mx-auto flex flex-wrap">
          <p class="leading-relaxed text-base ml-8">
            Our finance application process couldn't be simpler, if you let us
            know what you need and we'll help find the right product for you
            (loan or grant). Before you begin, please have your referral details
            and necessary documents to prove your identity (like a driving
            licence, council tax bill or utility bill). We may ask you some
            questions about your income and expenditure, but we only ask the
            minimum questions we need.
          </p>
        </div>
      </section>

      <section class="text-gray-600 body-font mt-8">
        <div class="container mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="hidden md:block md:w-1/3 pr-10 text-base">
              <ApplicationTimeline atStep={step} />
            </div>
            <div className="w-full md:w-2/3">{stepRenderer(step)}</div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Apply;
