export const companyName = () => {
  return whichRegion() === "uk" ? "ZeroPA" : "Argid";
};

export const companyRegisteredCountry = () => {
  return whichRegion() === "uk" ? "England and Wales" : "Isle of Man";
};

export const companyNumber = () => {
  return whichRegion() === "uk" ? "06872579" : "135247C";
};

export const companyRegisteredAddress = () => {
  return whichRegion() === "uk"
    ? "44 Provident House, Harpur Street, Bedford, England, MK40 2QT"
    : "The Interest Free Loans Company, 3rd Floor, 10 Prospect Hill, Douglas, IM1 1EJ, Isle of Man";
};

export const whichRegion = () => {
  if (process.env && process.env.REACT_APP_REGION) {
    return process.env.REACT_APP_REGION;
  } else {
    return "uk";
  }
};

export const whichBrand = () => {
  if (process.env && process.env.REACT_APP_BRAND) {
    return process.env.REACT_APP_BRAND;
  } else {
    return "zeropa";
  }
};

export const whichTitle = () => {
  return whichRegion() === "uk"
    ? "Zeropa - Interest Free Loans"
    : "Argid - Interest Free Loans brought to you by The Interest Free Loans Company";
};

export const whichDescription = () => {
  return whichRegion() === "uk"
    ? "Zeropa provides interest free loans and is here to disrupt, educate and lend"
    : "Argid provides interest free loans and is here to disrupt, educate and lend";
};

export const livingLocation = () => {
  return whichRegion() === "uk" ? "United Kingdom" : "Isle of Man";
};
