import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { companyName } from "../../core/utils";

const FeatureBlock = () => {
  let navigate = useNavigate();

  const applyHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            {companyName()}'s alternative lending
          </h1>
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Using {companyName()}, you can get an alternative to pay-day
            lending, improve your credit rating and get special deals on white
            goods and house repairs. We're one of the UK's first lenders whose
            aim is to improve the life of the under-banked and under-served.
          </p>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-indigo-500 mb-5 flex-shrink-0">
              <FontAwesomeIcon
                icon="fa-handshake"
                className="text-purple-600 text-2xl"
              />
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Loans
              </h2>
              <p class="leading-relaxed text-base">
                We will offer interest free loans between &pound;50 and
                &pound;150 in up to 12 instalments. You can apply online and
                receive a decision and your funds within 5 days. We give no cash
                loans, but pay for goods and services directly.{" "}
                {/* <span class="text-purple-600">Loans to launch in Q4 2024!</span> */}
              </p>
              <button
                class="flex mx-auto mt-16 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
                onClick={applyHandler}
              >
                Apply for a loan
              </button>
            </div>
          </div>
          <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-indigo-500 mb-5 flex-shrink-0">
              <FontAwesomeIcon
                icon="fa-dove"
                className="text-purple-600 text-2xl"
              />
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Grants
              </h2>
              <p class="leading-relaxed text-base">
                We can offer small grants up to &pound;50 to help you through
                any current financial crisis. You need to have a referral from a
                Citizens Advice Bureau, Credit Union, Food Bank, School, Place
                of Worship or other Community Group.
              </p>
              <a
                href="/grants"
                class="mt-3 text-purple-500 inline-flex items-center cursor-pointer text-base"
              >
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>

              <button
                class="flex mx-auto mt-16 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
                onClick={applyHandler}
              >
                Apply for a grant
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureBlock;
