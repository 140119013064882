import Header from "../components/Header/Header";
import loans from "../assets/5.jpg";
// import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { companyName } from "../core/utils";

const Loans = () => {
  // let navigate = useNavigate();

  // const applyHandler = () => {
  //   navigate("/apply", { replace: false });
  // };

  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={loans} className="min-w-full object-cover" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 className="title-font sm:text-4xl text-3x font-medium">Loans</h1>
          <span className="mb-4 leading-tight  text-1xl drop-shadow block">
            Interest free loans
          </span>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Borrow &pound;50 - &pound;150 in up to 12 instalments
          </h2>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base">
              {companyName()} offers interest free loans to the financially
              disadvantaged and vulnerable. Our loans of £50-150, in up to 12
              instalments over 12 weeks or 12 months. We will make payment
              direct to the Charity Shop Gift Card. This will allow you to spend
              in a partner charity shop.
            </p>
            <p className="leading-relaxed text-base mt-8">
              To be eligible for our loans, you must:
            </p>
            <ul className="list-disc mt-8 ml-8 mb-8 text-base">
              <li>Be over 18</li>
              <li>Live permanently in the United Kingdom</li>
              <li>
                Provide details of your income and expenditure and you may be
                required to supply payslips and proof of benefits and bank
                statements.
              </li>
            </ul>
            {/* <p className="leading-relaxed text-base text-indigo-600">
              Loans will launch in Q4 2024!
            </p> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Loans;
