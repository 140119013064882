import logo from "../../assets/logo/zeropa-on-transparent.png";

const Logo = () => {
  return (
    <img
      src={logo}
      alt="Zeropa logo"
      title="Zeropa - Interest free loans"
      className="zeropa-logo"
      style={{ margin: "0 auto" }}
    />
  );
};

export default Logo;
