import Header from "../components/Header/Header";
import invest from "../assets/invest.jpg";
import Footer from "../components/Footer/Footer";

const Investors = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img
          src={invest}
          className="min-w-full object-cover object-top"
          alt=""
        />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 className="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            Invest &amp; lend
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            Make a positive difference to others
          </span>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Investors &amp; lenders
          </h2>
          <div className="md:w-3/5 md:pl-6">
            <h3 className="text-lg font-bold">Executive summary</h3>
            <p className="leading-relaxed text-base mb-4">
              ZeroPA offers UK ESG investors a chance for significant financial
              returns while creating transformative social impact. We provide
              interest-free loans to meet critical societal needs, empower
              underserved communities, and boost economic resilience through
              comprehensive financial education.
            </p>

            <h3 className="text-lg font-bold">Market opportunity</h3>
            <p className="leading-relaxed text-base mb-4">
              With growing demand for impactful investments, ZeroPA allows
              high-net-worth individuals and institutions to achieve competitive
              returns while making a measurable difference in UK communities.
              Our scalable model ensures sustainability and leadership in
              socially responsible investing.
            </p>

            <h3 className="text-lg font-bold">Business model</h3>
            <p className="leading-relaxed text-base mb-4">
              ZeroPA secures funding from corporations and individual investors,
              deploying it as interest-free loans to those facing financial
              barriers. This ensures predictable revenue through structured
              repayments and amplifies our impact by expanding access to
              financial resources.
            </p>

            <h3 className="text-lg font-bold">Competitive advantage</h3>
            <p className="leading-relaxed text-base mb-4">
              Our edge lies in combining innovative financial products with
              robust educational initiatives, empowering communities to build
              financial resilience. Our proven track record and rigorous
              governance inspire confidence in investors seeking impactful and
              ethical investments.
            </p>

            <h3 className="text-lg font-bold">Financial projections</h3>
            <p className="leading-relaxed text-base mb-4">
              ZeroPA&#39;s financial projections show a clear path to
              sustainable growth and attractive returns. With expanding
              partnerships and diversified revenue streams, we anticipate
              accelerated growth and positive cash flow, offering investors
              competitive returns and significant societal impact.
            </p>

            <h3 className="text-lg font-bold">Impact measurement</h3>
            <p className="leading-relaxed text-base mb-4">
              We use rigorous metrics to quantify our initiatives&#39;
              effectiveness, such as improvements in financial literacy, loan
              repayment rates, and community economic stability. Transparent
              reporting allows investors to track their social return on
              investment (SROI) and see the tangible impact of their
              contributions.
            </p>

            <h3 className="text-lg font-bold">Addressing UK poverty</h3>
            <p className="leading-relaxed text-base mb-4">
              With 7.8% of the UK in persistent poverty and 22% living in
              poverty, including 4.2 million children, ZeroPA aims to tackle
              these challenges by providing financial resources and education to
              help individuals achieve long-term stability.
            </p>

            <h3 className="text-lg font-bold">Conclusion</h3>
            <p className="leading-relaxed text-base mb-4">
              Invest in ZeroPA to align your financial goals with meaningful
              social impact. Together, we can empower individuals and
              communities, foster economic resilience, and drive sustainable
              growth through innovative financial solutions, creating a more
              inclusive and prosperous society.
            </p>

            <hr className="mb-4" />

            <p className="leading-relaxed text-base mb-4">
              If you'd like to invest in ZeroPA please email{" "}
              <a
                href="mailto:investors@zeropa.co.uk"
                className="text-indigo-600 underline"
              >
                investors@zeropa.co.uk
              </a>
              .
            </p>
            <p className="leading-relaxed text-base mb-4">
              If you'd like to become a peer to peer lender to help UK residents
              in need please email{" "}
              <a
                href="mailto:lenders@zeropa.co.uk"
                className="text-indigo-600 underline"
              >
                lenders@zeropa.co.uk
              </a>
              .
            </p>
            <p className="leading-relaxed text-base mb-4">
              You can read more about ZeroPA in the media via{" "}
              <a
                href="https://app.newspage.media/zeropa-madad-uk-ltd"
                className="text-indigo-600 underline"
              >
                https://app.newspage.media/zeropa-madad-uk-ltd
              </a>
            </p>
            <p className="leading-relaxed text-base mb-4">
              Risk Warning: capital at risk and there may be limited ability to
              trade shares as not publicly listed or access loan capital on
              demand.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Investors;
