import React from "react";
import { useNavigate } from "react-router-dom";
import LogoDark from "../Logo/LogoDark";

const Header = () => {
  let navigate = useNavigate();

  const applyHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <header className="text-gray-600 body-font bg-white/90 drop-shadow-2xl z-10 fixed w-full border-b-1 border-white">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <div
          className="flex title-font font-medium items-center text-gray-900 mt-4 md:mb-0"
          style={{ width: "220px" }}
        >
          <a href="/">
            <LogoDark />
          </a>
        </div>
        <nav className="md:ml-auto md:mr-auto flex-wrap items-center text-base justify-center hidden md:flex">
          <a href="/about" className="mr-5 hover:text-gray-900 cursor-pointer">
            About
          </a>
          <a
            href="/how-it-works"
            className="mr-5 hover:text-gray-900 cursor-pointer"
          >
            How it works
          </a>
          <a href="/loans" className="mr-5 hover:text-gray-900 cursor-pointer">
            Loans
          </a>
          <a href="/grants" className="mr-5 hover:text-gray-900 cursor-pointer">
            Grants
          </a>
          <a
            href="/contact"
            className="mr-5 hover:text-gray-900 cursor-pointer"
          >
            Contact &amp; Help
          </a>
          {/* <a className="mr-5 hover:text-gray-900">Fourth Link</a> */}
        </nav>
        <button
          className="items-center bg-purple-100 border-0 py-1 pb-2 px-3 focus:outline-none hover:bg-purple-200 rounded text-base mt-4 md:mt-0 hidden lg:inline-flex"
          onClick={applyHandler}
        >
          Apply for a loan or grant
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;
