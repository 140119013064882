import zeropa from "../../assets/logo/zeropa-dark.png";

const LogoDark = () => {
  return (
    <img
      src={zeropa}
      alt="ZeroPA logo"
      title="ZeroPA - Interest free loans"
      className="argid-logo"
      style={{ margin: "0 auto" }}
    />
  );
};

export default LogoDark;
