import Header from "../components/Header/Header";
import hooray from "../assets/8.jpg";
import Footer from "../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={hooray} className="min-w-full object-cover" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-white">
            Contact &amp; Help
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block text-white">
            We're here, if you need us
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Help
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <p class="leading-relaxed text-base">
              Help is just around the corner. Whether you have a loan or grant
              with us, or are considering making an application then this is
              where we can get you to the right person. Simply select a tile
              below that matches your enquiry and we will come back to you as
              soon as possible (but always within 5 working days).
            </p>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <a
                href="mailto:hello@zeropa.co.uk?subject=General"
                alt="Send a general enquiry"
              >
                <div class="border border-gray-200 p-6 rounded-lg bg-indigo-100">
                  <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-200 text-indigo-500 mb-4">
                    <FontAwesomeIcon
                      icon="fa-bolt"
                      className="text-indigo-600 text-lg"
                    />
                  </div>
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                    General
                  </h2>
                  <p class="leading-relaxed text-base">
                    Any general enquiry, request, question or feedback about
                    ZeroPA.
                  </p>
                </div>
              </a>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <a
                href="mailto:hello@zeropa.co.uk?subject=Products"
                alt="Send a product enquiry"
              >
                <div class="border border-gray-200 p-6 rounded-lg bg-indigo-100">
                  <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-200 text-indigo-500 mb-4">
                    <FontAwesomeIcon
                      icon="fa-store"
                      className="text-indigo-600 text-lg"
                    />
                  </div>
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                    Products
                  </h2>
                  <p class="leading-relaxed text-base">
                    Questions about our loans, grants or help initiatives.
                  </p>
                </div>
              </a>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <a
                href="mailto:hello@zeropa.co.uk?subject=Loans"
                alt="Send a ZeroPA loan enquiry"
              >
                <div class="border border-gray-200 p-6 rounded-lg bg-indigo-100">
                  <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-200 text-indigo-500 mb-4">
                    <FontAwesomeIcon
                      icon="fa-handshake"
                      className="text-indigo-600 text-lg"
                    />
                  </div>
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                    Loans
                  </h2>
                  <p class="leading-relaxed text-base">
                    You have a ZeroPA loan and have questions or feedback.
                  </p>
                </div>
              </a>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <a
                href="mailto:hello@zeropa.co.uk?subject=Grants"
                alt="Send a ZeroPA grant enquiry"
              >
                <div class="border border-gray-200 p-6 rounded-lg bg-indigo-100">
                  <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-200 text-indigo-500 mb-4">
                    <FontAwesomeIcon
                      icon="fa-dove"
                      className="text-indigo-600 text-lg"
                    />
                  </div>
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                    Grants
                  </h2>
                  <p class="leading-relaxed text-base">
                    You have a ZeroPA grant and have questions or feedback.
                  </p>
                </div>
              </a>
            </div>
            {/* <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                  Financial Help
                </h2>
                <p class="leading-relaxed text-base">
                  You have questions about getting access to financial help and
                  advice
                </p>
              </div>
            </div> */}
            <div class="xl:w-2/3 md:w-1/2 p-4">
              <a
                href="mailto:hello@zeropa.co.uk?subject=Complaint"
                alt="Send a ZeroPA complaint"
              >
                <div class="border border-gray-200 p-6 rounded-lg bg-indigo-100">
                  <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-200 text-indigo-500 mb-4">
                    <FontAwesomeIcon
                      icon="fa-bullhorn"
                      className="text-indigo-600 text-lg"
                    />
                  </div>
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                    Complaints
                  </h2>
                  <p class="leading-relaxed text-base">
                    You would like to complain about something. We aim to
                    acknowledge your complaint within 24 hours and deal with it
                    within a week.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container mx-auto flex sm:flex-nowrap flex-wrap mt-10 p-6 text-base">
          <p>
            If you are having financial problems, consider using benefits
            checkers and other relevant information from Turn2Us (
            <a
              href="https://turn2us.org.uk"
              target="_blank"
              rel="noreferrer"
              className="text-indigo-500"
            >
              turn2us.org.uk
            </a>
            ) or EntitledTo (
            <a
              href="https://entitledto.co.uk"
              target="_blank"
              rel="noreferrer"
              className="text-indigo-500"
            >
              entitledto.co.uk
            </a>
            ) or for debt advice and host of other information go to StepChange
            (
            <a
              href="https://stepchange.org"
              target="_blank"
              rel="noreferrer"
              className="text-indigo-500"
            >
              stepchange.org
            </a>
            ).
          </p>
        </div>
      </section>
      <section class="text-gray-600 body-font relative mt-8 p-8">
        <div class="container mx-auto border-t-2 border-purple-100 pt-8">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font leading-none pb-8">
            ZeroPA
          </h2>
        </div>

        <div class="container mx-auto flex sm:flex-nowrap flex-wrap">
          <div class="w-full bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative h-128 min-h-128">
            <iframe
              class="absolute inset-0"
              style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src={`https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sWenta+Business+Centre,+Watford,+WD24+7ND!6i14!3m1!1sen!5m1!1sen `}
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div class="lg:w-1/2 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1">
                  Zeropa Ltd
                  <br />
                  Wenta Business Centre
                  <br />
                  Colne Way
                  <br />
                  Watford
                  <br />
                  England
                  <br />
                  WD24 7ND
                  <br />
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  class="text-indigo-500 leading-relaxed"
                  href="mailto:hello@zeropa.co.uk"
                >
                  hello@zeropa.co.uk
                </a>
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed">0300 102 1626</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="text-gray-600 body-font relative mt-8 p-8">
        <div class="container mx-auto border-t-2 border-purple-100 pt-8">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font leading-none pb-8">
            The Interest Free Loans Company, Isle of Man
          </h2>
        </div>

        <div class="container mx-auto flex sm:flex-nowrap flex-wrap">
          <div class="w-full bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative h-128 min-h-128">
            <iframe
              class="absolute inset-0"
              style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src={`https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s3rd%20Floor,%2010%20Prospect%20Hill,%20Douglas,%20IM1%201EJ,%20Isle%20of%20Man!6i14!3m1!1sen!5m1!1sen`}
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div class="lg:w-2/3 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1">
                  The Interest Free Loans Company
                  <br />
                  3rd Floor
                  <br />
                  Prospect Hill
                  <br />
                  Douglas
                  <br />
                  IM1 1EJ
                  <br />
                  Isle of Man
                </p>
              </div>
              <div class="lg:w-1/3 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  class="text-indigo-500 leading-relaxed"
                  href="mailto:hello@argid.im"
                >
                  hello@argid.im
                </a>
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed">123-456-7890</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default Contact;
